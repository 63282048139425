import React from "react";
import theme from "theme";
import { Theme, Image, Link, Box, Section, Text, Em, Span, Input, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"aisc-davis"} />
		<Helmet>
			<title>
				AISC Davis
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<meta property={"og:title"} content={"AISC Davis"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/HFAI%20Favicon.png?v=2024-02-10T08:45:31.376Z"} type={"image/x-icon"} />
		</Helmet>
		<Section
			background="--color-aiscDavisBlack"
			margin="0 0 0 0"
			padding="48px 160px 24px 160px"
			quarkly-title="Header"
			width="100% border-box"
			sm-padding="48px 48px 24px 48px"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				justify-content="space-between"
				align-items="center"
				margin="0px 0 0px 0px"
				width="100%"
				max-width="100%"
				sm-width="100% border-box"
				sm-min-width="fit-content"
				sm-min-height="fit-content"
				sm-height="fit-content"
				sm-align-items="center"
				sm-justify-content="space-between"
			/>
			<Image
				src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Logo%20Outline%20White.png?v=2023-11-07T22:12:47.498Z"
				display="block"
				height="32px"
				width="32px"
				quarkly-title="Logo"
				sm-margin="0px 24px 0px 0px"
				min-height="32px"
				min-width="32px"
				srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Logo%20Outline%20White.png?v=2023-11-07T22%3A12%3A47.498Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Logo%20Outline%20White.png?v=2023-11-07T22%3A12%3A47.498Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Logo%20Outline%20White.png?v=2023-11-07T22%3A12%3A47.498Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Logo%20Outline%20White.png?v=2023-11-07T22%3A12%3A47.498Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Logo%20Outline%20White.png?v=2023-11-07T22%3A12%3A47.498Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Logo%20Outline%20White.png?v=2023-11-07T22%3A12%3A47.498Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Logo%20Outline%20White.png?v=2023-11-07T22%3A12%3A47.498Z&quality=85&w=3200 3200w"
				sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
			/>
			<Box
				min-width="100px"
				min-height="fit-content"
				quarkly-title="Pages"
				sm-height="fit-content"
				sm-min-height="fit-content"
				height="fit-content"
			>
				<Link
					href="/index"
					color="#F7FBFF"
					max-width="fit-content"
					text-decoration-line="initial"
					padding="0px 32px 0px 0px"
					font="200 16px sans-serif"
					hover-color="#FF43AD"
					transition="all 0.2s ease 0s"
					sm-padding="0px 0 0px 0px"
					height="100% border-box"
					min-height="fit-content"
				>
					Humans for AI
				</Link>
				<Link
					href="/aisc"
					color="--light"
					max-width="fit-content"
					text-decoration-line="initial"
					font="200 16px sans-serif"
					hover-color="#FF43AD"
					transition="all 0.2s ease 0s"
					sm-margin="0px 0px 0px 24px"
					height="100% border-box"
					min-height="fit-content"
				>
					AISC
				</Link>
			</Box>
		</Section>
		<Section
			background="url(https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Davis%20Website%20Hero%20Image.png?v=2023-11-07T21:35:08.566Z) 0% 0%/cover no-repeat scroll"
			color="--aiscDavisBlack"
			display="flex"
			height="100%"
			overflow-x="visible"
			overflow-y="visible"
			min-height="800px"
			justify-content="center"
			align-items="center"
			quarkly-title="Hero"
			max-height="100%"
			sm-background="url(https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Davis%20Website%20Hero%20Image.png?v=2023-11-07T21:35:08.566Z) 50% 0%/cover no-repeat scroll"
			sm-padding="0 0 0 0"
			sm-min-height="700px"
		>
			<Override slot="SectionContent" padding="0px 0px 200px 0px" sm-padding="0px 0px 164px 0px" />
			<Text
				margin="0px 0px 0px 0px"
				background="rgba(0, 0, 0, 0)"
				color="--light"
				text-align="center"
				font="normal 500 48px --fontFamily-sans"
				sm-font="normal 500 40px --fontFamily-sans"
			>
				AI STUDENT COLLECTIVE
			</Text>
			<Text
				margin="0px 0px 0px 0px"
				background="rgba(0, 0, 0, 0)"
				color="--light"
				text-align="center"
				font="normal 200 48px --fontFamily-sans"
				sm-font="normal 200 40px --fontFamily-sans"
			>
				@ UC DAVIS
			</Text>
			<Text
				margin="40px 0px 0px 0px"
				background="rgba(0, 0, 0, 0)"
				color="#51B7FF"
				text-align="center"
				font="normal 300 24px --fontFamily-sans"
				sm-padding="0px 48px 0px 48px"
			>
				Creating a future of AI literacy for all
			</Text>
		</Section>
		<Section
			color="#242424"
			display="flex"
			overflow-x="visible"
			overflow-y="visible"
			justify-content="center"
			align-items="center"
			max-height="100%"
			min-height="fit-content"
			height="100% border-box"
			text-align="center"
			background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20website%20about%20us%20bg.png?v=2024-01-08T04:01:51.382Z) 0% 0%/cover"
			padding="50px 0 150px 0"
			quarkly-title="About Us"
			sm-padding="48px 0 84px 0"
			sm-width="100%"
			sm-min-width="100%"
			sm-max-width="100%"
		>
			<Override
				slot="SectionContent"
				align-items="center"
				width="1152px"
				sm-width="100%"
				sm-min-width={0}
				sm-max-width="100%"
				sm-padding="0px 36px 0px 36px"
			/>
			<Text
				margin="0px 64px 0px 64px"
				background="rgba(0, 0, 0, 0)"
				color="--light"
				text-align="center"
				font="normal 500 36px --fontFamily-sans"
				sm-font="normal 500 32px --fontFamily-sans"
				sm-width="100% border-box"
				sm-margin="0px 0 0px 0"
				sm-min-width="fit-content"
			>
				Welcome to the AI Student Collective{" "}
				<br />
				(AISC) @ UC Davis!
			</Text>
			<Text
				margin="40px 0px 0px 0px"
				background="rgba(0, 0, 0, 0)"
				color="#cccccc"
				text-align="center"
				font="normal 200 24px --fontFamily-sans"
				max-width="70%"
				sm-width="100% border-box"
				sm-max-width="100%"
				sm-min-width="fit-content"
				sm-font="normal 200 20px --fontFamily-sans"
			>
				We're on a passionate mission to shape a future of AI literacy for all by empowering students from diverse backgrounds to thrive in the AI landscape.
				<br />
				<br />
				Since our launch in January 2023, we've grown to serve over 1,000 students, offering exciting opportunities for involvement in technical, creative, and business roles. Join us in the AI revolution and be part of something extraordinary.
			</Text>
			<Image
				src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Davis%20Website%20Glow.png?v=2023-11-07T22:40:38.455Z"
				display="block"
				padding="0px 0px 0 0px"
				sm-width="100% border-box"
				sm-padding="24px 0px 0 0px"
				sm-min-width="100%"
				sm-max-width="100%"
				width="700px"
				md-min-width="50% content-box"
				md-width="700px"
				lg-min-width="50% content-box"
				xl-min-width="50% content-box"
				min-width="50% content-box"
				height="auto"
				xl-height="auto"
				flex="0 0 auto"
				margin="16px 0px 0px 0px"
				srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Davis%20Website%20Glow.png?v=2023-11-07T22%3A40%3A38.455Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Davis%20Website%20Glow.png?v=2023-11-07T22%3A40%3A38.455Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Davis%20Website%20Glow.png?v=2023-11-07T22%3A40%3A38.455Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Davis%20Website%20Glow.png?v=2023-11-07T22%3A40%3A38.455Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Davis%20Website%20Glow.png?v=2023-11-07T22%3A40%3A38.455Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Davis%20Website%20Glow.png?v=2023-11-07T22%3A40%3A38.455Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Davis%20Website%20Glow.png?v=2023-11-07T22%3A40%3A38.455Z&quality=85&w=3200 3200w"
				sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
			/>
		</Section>
		<Section
			color="#2e2e2e"
			display="flex"
			overflow-x="visible"
			overflow-y="visible"
			max-height="100%"
			min-height="fit-content"
			height="100%"
			text-align="center"
			quarkly-title="Pillars"
			width="100% border-box"
			padding="64px 160px 72px 160px"
			max-width="100%"
			flex-direction="row"
			background="--color-aiscDavisDarkGray"
			sm-padding="84px 36px 100px 36px"
		>
			<Override
				slot="SectionContent"
				align-items="flex-start"
				flex-direction="row"
				margin="0px 0px 0px 0"
				max-width="none"
				width="100% border-box"
				md-overflow-x="auto"
				lg-flex-wrap="wrap"
				display="flex"
				min-width="100%"
				justify-content="space-between"
				flex-wrap="wrap"
			/>
			<Text
				margin="0px 0px 0px 0px"
				background="rgba(0, 0, 0, 0)"
				color="--light"
				text-align="center"
				font="normal 500 36px --fontFamily-sans"
				min-width="fit-content"
				display="flex"
				width="fit-content"
				padding="0px 0px 80px 0px"
				sm-text-align="left"
				sm-width="100% border-box"
				sm-padding="0px 0px 0 0px"
				sm-margin="0px 0px 80px 0px"
			>
				Our 3 Pillars
			</Text>
			<Box
				min-width="none"
				min-height="100px"
				display="flex"
				justify-content="space-between"
				width="auto"
				max-width="none"
				flex-direction="row"
				padding="0px 0px 0px 0px"
				flex-wrap="wrap"
				sm-flex-direction="column"
				sm-width="100% border-box"
				sm-height="fit-content"
			>
				<Box
					min-height="100px"
					width="fit-content"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					padding="0px 0px 0px 0px"
					quarkly-title="Accessibility"
					height="fit-content"
					margin="12px 60px 0px 0px"
					sm-width="auto"
					sm-margin="0 0 48px 0px"
					sm-padding="0px 0px 0 0px"
				>
					<Box
						min-width="100px"
						min-height="100px"
						quarkly-title="Header"
						sm-display="flex"
						sm-align-items="center"
						sm-justify-content="flex-start"
						sm-height="fit-content"
						sm-min-height="fit-content"
						sm-margin="0px 0px 16px 0px"
					>
						<Image
							src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Davis%20Globe%20Icon.png?v=2023-11-08T00:10:22.634Z"
							display="block"
							height="30px"
							padding="0px 0px 48px 0px"
							width="100% border-box"
							sm-width="100% border-box"
							sm-padding="0px 0px 0 0px"
							sm-margin="0px 16px 0px 0px"
							sm-height="24px"
							srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Davis%20Globe%20Icon.png?v=2023-11-08T00%3A10%3A22.634Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Davis%20Globe%20Icon.png?v=2023-11-08T00%3A10%3A22.634Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Davis%20Globe%20Icon.png?v=2023-11-08T00%3A10%3A22.634Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Davis%20Globe%20Icon.png?v=2023-11-08T00%3A10%3A22.634Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Davis%20Globe%20Icon.png?v=2023-11-08T00%3A10%3A22.634Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Davis%20Globe%20Icon.png?v=2023-11-08T00%3A10%3A22.634Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Davis%20Globe%20Icon.png?v=2023-11-08T00%3A10%3A22.634Z&quality=85&w=3200 3200w"
							sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
						/>
						<Text
							margin="0px 0px 0px 0px"
							color="--light"
							text-align="left"
							font="500 20px sans-serif"
							padding="0px 0px 12px 0px"
							width="fit-content"
							sm-width="100% border-box"
							sm-height="fit-content"
							sm-display="flex"
							sm-padding="0px 0px 0 0px"
						>
							ACCESSIBILITY
						</Text>
					</Box>
					<Text
						margin="0px 0px 0px 0px"
						color="--aiscDavisLightGray"
						font="200 18px sans-serif"
						text-align="left"
						padding="0px 0px 0px 0px"
						width="148px"
						sm-width="auto"
						sm-min-width="8px"
					>
						Promoting AI accessibility for all by breaking down the barriers to knowledge and opportunity
					</Text>
				</Box>
				<Box
					min-height="100px"
					width="fit-content"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					padding="0px 0px 0px 0px"
					quarkly-title="Literacy"
					height="fit-content"
					margin="12px 48px 0px 0px"
					sm-width="auto"
					sm-margin="0 0 48px 0px"
					sm-padding="0px 0px 0 0px"
				>
					<Box
						min-width="100px"
						min-height="100px"
						quarkly-title="Header"
						sm-display="flex"
						sm-align-items="center"
						sm-justify-content="flex-start"
						sm-height="fit-content"
						sm-min-height="fit-content"
						sm-margin="0px 0px 16px 0px"
					>
						<Image
							src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Davis%20Handshake%20Icon.png?v=2023-11-08T00:10:54.858Z"
							display="block"
							height="30px"
							padding="0px 0px 48px 0px"
							sm-padding="0px 0px 0 0px"
							sm-margin="0px 16px 0px 0px"
							sm-height="24px"
							srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Davis%20Handshake%20Icon.png?v=2023-11-08T00%3A10%3A54.858Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Davis%20Handshake%20Icon.png?v=2023-11-08T00%3A10%3A54.858Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Davis%20Handshake%20Icon.png?v=2023-11-08T00%3A10%3A54.858Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Davis%20Handshake%20Icon.png?v=2023-11-08T00%3A10%3A54.858Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Davis%20Handshake%20Icon.png?v=2023-11-08T00%3A10%3A54.858Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Davis%20Handshake%20Icon.png?v=2023-11-08T00%3A10%3A54.858Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Davis%20Handshake%20Icon.png?v=2023-11-08T00%3A10%3A54.858Z&quality=85&w=3200 3200w"
							sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
						/>
						<Text
							margin="0px 0px 0px 0px"
							color="--light"
							text-align="left"
							font="500 20px sans-serif"
							padding="0px 0px 12px 0px"
							width="fit-content"
							sm-width="100% border-box"
							sm-height="fit-content"
							sm-display="flex"
							sm-padding="0px 0px 0 0px"
						>
							LITERACY
						</Text>
					</Box>
					<Text
						margin="0px 0px 0px 0px"
						color="--aiscDavisLightGray"
						font="200 18px sans-serif"
						text-align="left"
						padding="0px 0px 0px 0px"
						width="148px"
						sm-width="auto"
						sm-min-width="8px"
					>
						Empowering students with enriching resources and experiences to shape the future
					</Text>
				</Box>
				<Box
					min-height="100px"
					width="fit-content"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					padding="0px 0px 0px 0px"
					quarkly-title="Diversity"
					height="fit-content"
					margin="12px 0 0px 0px"
					sm-width="auto"
					sm-margin="0 0 0 0px"
					sm-padding="0px 0px 0 0px"
				>
					<Box
						min-width="100px"
						min-height="100px"
						quarkly-title="Header"
						sm-display="flex"
						sm-align-items="center"
						sm-justify-content="flex-start"
						sm-height="fit-content"
						sm-min-height="fit-content"
						sm-margin="0px 0px 16px 0px"
					>
						<Image
							src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Davis%20Community%20Icon.png?v=2023-11-08T00:11:12.221Z"
							display="block"
							height="30px"
							padding="0px 0px 48px 0px"
							sm-padding="0px 0px 0 0px"
							sm-margin="0px 16px 0px 0px"
							sm-height="24px"
							srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Davis%20Community%20Icon.png?v=2023-11-08T00%3A11%3A12.221Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Davis%20Community%20Icon.png?v=2023-11-08T00%3A11%3A12.221Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Davis%20Community%20Icon.png?v=2023-11-08T00%3A11%3A12.221Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Davis%20Community%20Icon.png?v=2023-11-08T00%3A11%3A12.221Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Davis%20Community%20Icon.png?v=2023-11-08T00%3A11%3A12.221Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Davis%20Community%20Icon.png?v=2023-11-08T00%3A11%3A12.221Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Davis%20Community%20Icon.png?v=2023-11-08T00%3A11%3A12.221Z&quality=85&w=3200 3200w"
							sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
						/>
						<Text
							margin="0px 0px 0px 0px"
							color="--light"
							text-align="left"
							font="500 20px sans-serif"
							padding="0px 0px 12px 0px"
							width="fit-content"
							sm-width="100% border-box"
							sm-height="fit-content"
							sm-display="flex"
							sm-padding="0px 0px 0 0px"
						>
							DIVERSITY
						</Text>
					</Box>
					<Text
						margin="0px 0px 0px 0px"
						color="--aiscDavisLightGray"
						font="200 18px sans-serif"
						text-align="left"
						padding="0px 0px 0px 0px"
						width="148px"
						sm-width="auto"
						sm-min-width="8px"
					>
						Celebrating diversity in AI, fostering innovation through varied perspectives
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			color="#2e2e2e"
			display="flex"
			overflow-x="visible"
			overflow-y="visible"
			align-items="flex-start"
			max-height="100%"
			min-height="fit-content"
			height="100%"
			text-align="center"
			background="url(https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20what%20we%20offer%20cube%20bg.png?v=2024-01-08T05:40:29.141Z) 100% 50%/cover"
			padding="124px 160px 148px 160px"
			quarkly-title="What We Offer + Past Highlights"
			justify-content="center"
			max-width="100%"
			width="100% border-box"
			sm-padding="84px 36px 100px 36px"
			sm-height="fit-content"
			sm-background="url(https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20what%20we%20offer%20cube%20bg.png?v=2024-01-08T05:40:29.141Z) 100%/cover"
		>
			<Override
				slot="SectionContent"
				align-items="flex-start"
				flex-direction="column"
				margin="0px 0 0px 0"
				max-width="fit-content"
				width="auto"
			/>
			<Text
				margin="0px 0px 0px 0px"
				background="rgba(0, 0, 0, 0)"
				color="--light"
				text-align="center"
				font="normal 500 36px --fontFamily-sans"
				padding="0px 0px 80px 0px"
				min-width="fit-content"
				sm-margin="0px 0px 64px 0px"
				sm-padding="0px 0px 0 0px"
			>
				What We Offer
			</Text>
			<Section
				padding="0px 0px 184px 0px"
				justify-content="space-between"
				width="100% border-box"
				display="flex"
				max-width="fit-content"
				min-width="100%"
				quarkly-title="What We Offer"
				sm-margin="0 0 164px 0"
				sm-padding="0px 0px 0 0px"
			>
				<Override
					slot="SectionContent"
					margin="0px 0 0px 0"
					align-items="center"
					flex-direction="row"
					justify-content="space-between"
					width="auto"
					max-width="100%"
					min-width="fit-contentmin-content"
					sm-width="100% border-box"
					sm-flex-wrap="wrap"
					sm-height="fit-content"
					flex-wrap="wrap"
					height="fit-content"
					min-height="fit-content"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					display="block"
					max-width="min-content"
					max-height="fit-content"
					padding="0 0 0 0"
					width="auto"
					sm-width="100% border-box"
					sm-margin="0px 0 64px 0px"
					sm-max-width="100%"
					margin="0px 24px 24px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20networking%20events.png?v=2024-01-08T04:51:23.602Z"
						display="block"
						width="220px"
						height="auto"
						min-height="100%"
						padding="0px 0px 48px 0px"
						object-fit="contain"
						min-width="100%100%"
						filter="--grayscale"
						sm-width="100% border-box"
						sm-min-width="100%"
						sm-margin="0px 0px 24px 0px"
						sm-padding="0px 0px 0 0px"
						srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20networking%20events.png?v=2024-01-08T04%3A51%3A23.602Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20networking%20events.png?v=2024-01-08T04%3A51%3A23.602Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20networking%20events.png?v=2024-01-08T04%3A51%3A23.602Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20networking%20events.png?v=2024-01-08T04%3A51%3A23.602Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20networking%20events.png?v=2024-01-08T04%3A51%3A23.602Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20networking%20events.png?v=2024-01-08T04%3A51%3A23.602Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20networking%20events.png?v=2024-01-08T04%3A51%3A23.602Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Text
						margin="0px 0px 16px 0px"
						color="#51B7FF"
						text-align="left"
						font="500 20px sans-serif"
						padding="0px 0px 0 0px"
						sm-width="100% border-box"
						sm-height="fit-content"
						sm-padding="0px 0px 8px 0px"
					>
						NETWORKING EVENTS
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--aiscDavisLightGray"
						text-align="left"
						font="200 18px sans-serif"
						width="fit-content"
						sm-width="100% border-box"
						sm-height="fit-content"
					>
						Forge valuable connections and broaden your horizons
					</Text>
				</Box>
				<Box
					min-height="100%"
					max-height="fit-content"
					width="auto"
					height="auto"
					padding="0 0 0 0"
					max-width="min-content"
					min-width="100%100%"
					sm-margin="0px 0 64px 0px"
					sm-height="fit-contentauto"
					sm-max-width="100%"
					margin="0px 24px 24px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20technical%20consulting.png?v=2024-01-08T04:51:23.617Z"
						display="block"
						min-width="none"
						min-height="100%"
						padding="0px 0px 48px 0px"
						height="auto"
						width="220px"
						filter="--grayscale"
						sm-width="100% border-box"
						sm-min-width="100%"
						sm-padding="0px 0px 0 0px"
						sm-margin="0px 0px 24px 0px"
						srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20technical%20consulting.png?v=2024-01-08T04%3A51%3A23.617Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20technical%20consulting.png?v=2024-01-08T04%3A51%3A23.617Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20technical%20consulting.png?v=2024-01-08T04%3A51%3A23.617Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20technical%20consulting.png?v=2024-01-08T04%3A51%3A23.617Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20technical%20consulting.png?v=2024-01-08T04%3A51%3A23.617Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20technical%20consulting.png?v=2024-01-08T04%3A51%3A23.617Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20technical%20consulting.png?v=2024-01-08T04%3A51%3A23.617Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Text
						margin="0px 0px 16px 0px"
						color="#51B7FF"
						text-align="left"
						font="500 20px sans-serif"
						padding="0px 0px 0 0px"
						sm-padding="0px 0px 0 0px"
						sm-margin="0px 0px 8px 0px"
					>
						TECHNICAL CONSULTING
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--aiscDavisLightGray"
						text-align="left"
						font="200 18px sans-serif"
						width="fit-content"
					>
						Unlock your potential with hands-on technical guidance and mentorship
					</Text>
				</Box>
				<Box
					min-height="100%"
					max-height="fit-content"
					width="auto"
					height="auto"
					padding="0 0 0 0"
					max-width="min-content"
					min-width="100%100%"
					sm-margin="0px 0 64px 0px"
					sm-width="100% border-box"
					sm-min-width="100%"
					margin="0px 24px 24px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20case%20comps.png?v=2024-01-08T04:51:23.614Z"
						display="block"
						min-width="100%100%"
						min-height="100%"
						padding="0px 0px 48px 0px"
						height="auto"
						width="220px"
						filter="--grayscale"
						sm-width="100% border-box"
						sm-min-width="100%"
						sm-padding="0px 0px 0 0px"
						sm-margin="0px 0px 24px 0px"
						srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20case%20comps.png?v=2024-01-08T04%3A51%3A23.614Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20case%20comps.png?v=2024-01-08T04%3A51%3A23.614Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20case%20comps.png?v=2024-01-08T04%3A51%3A23.614Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20case%20comps.png?v=2024-01-08T04%3A51%3A23.614Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20case%20comps.png?v=2024-01-08T04%3A51%3A23.614Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20case%20comps.png?v=2024-01-08T04%3A51%3A23.614Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20case%20comps.png?v=2024-01-08T04%3A51%3A23.614Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Text
						margin="0px 0px 16px 0px"
						color="#51B7FF"
						text-align="left"
						font="500 20px sans-serif"
						padding="0px 0px 0 0px"
						sm-margin="0px 0px 8px 0px"
						sm-padding="0px 0px 0 0px"
					>
						CASE COMPETITIONS
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--aiscDavisLightGray"
						text-align="left"
						font="200 18px sans-serif"
						width="fit-content"
					>
						Sharpen your problem-solving skills and compete at the forefront of AI innovation
					</Text>
				</Box>
				<Box
					min-height="100%"
					max-height="fit-content"
					width="auto"
					height="auto"
					padding="0 0 0 0"
					max-width="min-content"
					min-width="100%100%"
					sm-width="100% border-box"
					sm-min-width="100%"
					margin="0px 0 24px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20workshops.png?v=2024-01-08T04:51:23.598Z"
						display="block"
						min-width="100%100%"
						min-height="100%"
						padding="0px 0px 48px 0px"
						height="auto"
						width="220px"
						filter="--grayscale"
						sm-width="100% border-box"
						sm-margin="0px 0px 24px 0px"
						sm-min-width="100%"
						sm-padding="0px 0px 0 0px"
						srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20workshops.png?v=2024-01-08T04%3A51%3A23.598Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20workshops.png?v=2024-01-08T04%3A51%3A23.598Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20workshops.png?v=2024-01-08T04%3A51%3A23.598Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20workshops.png?v=2024-01-08T04%3A51%3A23.598Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20workshops.png?v=2024-01-08T04%3A51%3A23.598Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20workshops.png?v=2024-01-08T04%3A51%3A23.598Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20workshops.png?v=2024-01-08T04%3A51%3A23.598Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Text
						margin="0px 0px 16px 0px"
						color="#51B7FF"
						text-align="left"
						font="500 20px sans-serif"
						padding="0px 0px 0 0px"
						sm-margin="0px 0px 8px 0px"
						sm-padding="0px 0px 0 0px"
					>
						DYNAMIC WORKSHOPS
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--aiscDavisLightGray"
						text-align="left"
						font="200 18px sans-serif"
						width="fit-content"
					>
						Collaborate with peers and gain insights through interactive workshops
					</Text>
				</Box>
			</Section>
			<Text
				margin="0px 0px 0px 0px"
				background="rgba(0, 0, 0, 0)"
				color="--light"
				text-align="center"
				font="normal 500 36px --fontFamily-sans"
				padding="0px 0px 80px 0px"
				min-width="fit-content"
				sm-margin="0px 0px 64px 0px"
				sm-padding="0px 0px 0 0px"
			>
				Past Highlights
			</Text>
			<Section
				padding="0px 0px 0px 0px"
				justify-content="space-between"
				display="flex"
				width="100% border-box"
				min-width="100%"
				max-width="fit-content"
				quarkly-title="Past Highlights"
				sm-height="fit-content"
				sm-width="100% border-box"
				sm-min-height="fit-content"
			>
				<Override
					slot="SectionContent"
					margin="0px 0 0px 0"
					align-items="center"
					flex-direction="row"
					justify-content="space-between"
					width="auto"
					min-width="fit-contentmin-content"
					max-width="100%"
					sm-flex-wrap="wrap"
					sm-height="fit-content"
					flex-wrap="wrap"
					height="fit-content"
					min-height="fit-content"
				/>
				<Box
					min-width="100px"
					min-height="100%"
					display="block"
					max-width="min-content"
					max-height="fit-content"
					padding="0 0 0 0"
					width="auto"
					height="auto"
					margin="0 24px 24px 0"
					sm-width="100% border-box"
					sm-min-width="100%"
					sm-margin="0px 0 64px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20wit%20panel.png?v=2024-01-11T02:50:00.586Z"
						display="block"
						height="auto"
						min-height="100%"
						padding="0px 0px 48px 0px"
						object-fit="contain"
						width="300px"
						min-width="100%100%"
						filter="--grayscale"
						sm-width="100% border-box"
						sm-min-width="100%"
						sm-padding="0px 0px 0 0px"
						sm-margin="0px 0px 24px 0px"
						srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20wit%20panel.png?v=2024-01-11T02%3A50%3A00.586Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20wit%20panel.png?v=2024-01-11T02%3A50%3A00.586Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20wit%20panel.png?v=2024-01-11T02%3A50%3A00.586Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20wit%20panel.png?v=2024-01-11T02%3A50%3A00.586Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20wit%20panel.png?v=2024-01-11T02%3A50%3A00.586Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20wit%20panel.png?v=2024-01-11T02%3A50%3A00.586Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20wit%20panel.png?v=2024-01-11T02%3A50%3A00.586Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Text
						margin="0px 0px 16px 0px"
						color="#51B7FF"
						text-align="left"
						font="500 20px sans-serif"
						padding="0px 0px 0 0px"
						sm-margin="0px 0px 8px 0px"
						sm-padding="0px 0px 0 0px"
					>
						WOMEN IN TECH PANEL
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--light"
						text-align="left"
						font="200 18px sans-serif"
						width="fit-content"
						padding="0px 0px 16px 0px"
						sm-margin="0px 0px 8px 0px"
						sm-padding="0px 0px 0 0px"
					>
						June 2023
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--aiscDavisLightGray"
						text-align="left"
						font="200 18px sans-serif"
						width="fit-content"
					>
						A panel-style event complete with free boba, pizza, and incredible insights into making it in both the tech industry and academia as a woman
					</Text>
				</Box>
				<Box
					min-height="100%"
					max-height="fit-content"
					width="auto"
					height="auto"
					padding="0 0 0 0"
					min-width="100%100%"
					max-width="min-content"
					margin="0 24px 24px 0"
					sm-width="100% border-box"
					sm-min-width="100%"
					sm-margin="0px 0 64px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20case%20comp.png?v=2024-01-11T02:51:07.752Z"
						display="block"
						min-height="100%"
						padding="0px 0px 48px 0px"
						height="auto"
						width="300px"
						min-width="none"
						filter="--grayscale"
						sm-width="100% border-box"
						sm-margin="0px 0px 24px 0px"
						sm-padding="0px 0px 0 0px"
						sm-min-width="100%"
						srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20case%20comp.png?v=2024-01-11T02%3A51%3A07.752Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20case%20comp.png?v=2024-01-11T02%3A51%3A07.752Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20case%20comp.png?v=2024-01-11T02%3A51%3A07.752Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20case%20comp.png?v=2024-01-11T02%3A51%3A07.752Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20case%20comp.png?v=2024-01-11T02%3A51%3A07.752Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20case%20comp.png?v=2024-01-11T02%3A51%3A07.752Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20case%20comp.png?v=2024-01-11T02%3A51%3A07.752Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Text
						margin="0px 0px 16px 0px"
						color="#51B7FF"
						text-align="left"
						font="500 20px sans-serif"
						padding="0px 0px 0 0px"
						sm-margin="0px 0px 8px 0px"
						sm-padding="0px 0px 0 0px"
					>
						AIMPACT CASE COMPETITION
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--light"
						text-align="left"
						font="200 18px sans-serif"
						width="fit-content"
						padding="0px 0px 16px 0px"
						sm-margin="0px 0px 8px 0px"
						sm-padding="0px 0px 0 0px"
					>
						May 2023
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--aiscDavisLightGray"
						text-align="left"
						font="200 18px sans-serif"
						width="fit-content"
					>
						A hackathon and pitch competition encapsulating the startup bootstrapping experience with judges and mentors from Google, C3.ai, EY, and Synopsys
					</Text>
				</Box>
				<Box
					min-height="100%"
					max-height="fit-content"
					width="auto"
					height="auto"
					padding="0 0 0 0"
					max-width="min-content"
					min-width="100%100%"
					sm-width="100% border-box"
					sm-min-width="100%"
					margin="0 0 24px 0"
				>
					<Image
						src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20launch%20event.png?v=2024-01-11T02:48:10.551Z"
						display="block"
						min-width="100%100%"
						min-height="100%"
						padding="0px 0px 48px 0px"
						height="auto"
						width="300px"
						filter="--grayscale"
						sm-width="100% border-box"
						sm-margin="0px 0px 24px 0px"
						sm-min-width="100%"
						sm-padding="0px 0px 0 0px"
						srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20launch%20event.png?v=2024-01-11T02%3A48%3A10.551Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20launch%20event.png?v=2024-01-11T02%3A48%3A10.551Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20launch%20event.png?v=2024-01-11T02%3A48%3A10.551Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20launch%20event.png?v=2024-01-11T02%3A48%3A10.551Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20launch%20event.png?v=2024-01-11T02%3A48%3A10.551Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20launch%20event.png?v=2024-01-11T02%3A48%3A10.551Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20launch%20event.png?v=2024-01-11T02%3A48%3A10.551Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Text
						margin="0px 0px 16px 0px"
						color="#51B7FF"
						text-align="left"
						font="500 20px sans-serif"
						padding="0px 0px 0 0px"
						sm-margin="0px 0px 8px 0px"
						sm-padding="0px 0px 0 0px"
					>
						AISC DAVIS LAUNCH EVENT
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--light"
						text-align="left"
						font="200 18px sans-serif"
						width="fit-content"
						padding="0px 0px 16px 0px"
						sm-margin="0px 0px 8px 0px"
						sm-padding="0px 0px 0 0px"
					>
						March 2023
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--aiscDavisLightGray"
						text-align="left"
						font="200 18px sans-serif"
						width="fit-content"
						height="fit-content"
					>
						A ballroom extravaganza that provided students with the opportunity to hear from and interact with AI thought leaders from Nvidia, Intel, and JPMorgan
					</Text>
				</Box>
			</Section>
		</Section>
		<Section
			color="#2e2e2e"
			display="flex"
			overflow-x="visible"
			overflow-y="visible"
			align-items="flex-start"
			max-height="100%"
			min-height="fit-content"
			height="100%"
			text-align="center"
			padding="100px 160px 124px 160px"
			quarkly-title="Articles"
			justify-content="center"
			max-width="100%"
			width="100% border-box"
			background="#2e2e2e"
			flex-direction="row"
			sm-padding="84px 36px 100px 36px"
		>
			<Override
				slot="SectionContent"
				align-items="flex-start"
				flex-direction="column"
				margin="0px 0 0px 0"
				max-width="100%"
				width="100% border-box"
				height="fit-content"
				xl-align-items="stretch"
				xl-max-width="100%"
				min-width="100%"
			/>
			<Box
				min-width="100%"
				min-height="fit-content"
				display="flex"
				width="100% border-box"
				flex-direction="row"
				justify-content="space-between"
				align-items="center"
				height="fit-content"
				margin="0px 0px 64px 0px"
				sm-flex-direction="column"
				sm-align-items="flex-start"
				max-width="100%"
			>
				<Text
					margin="0px 48px 0 0px"
					background="rgba(0, 0, 0, 0)"
					color="--light"
					text-align="center"
					font="normal 500 36px --fontFamily-sans"
					padding="0px 0px 0 0px"
					min-width="fit-content"
					display="flex"
					flex-direction="row"
					width="fit-content"
					sm-text-align="left"
					sm-margin="0px 0px 24px 0px"
					sm-padding="0px 0px 0 0px"
					sm-height="fit-content"
					sm-min-height="fit-content"
				>
					Read Our Articles
				</Text>
				<Link
					href="https://medium.com/@humansforai#:~:text=Humans%20For%20AI%20is%20a,banking%20to%20retail%20to%20education."
					color="--aiscDavisLightGray"
					font="200 20px --fontFamily-sans"
					target="_blank"
					transition="all 0.3s ease 0s"
					padding="0 0px 0px 0px"
					hover-color="#FF43AD"
					sm-margin="0 0px 0px 0px"
					sm-padding="0 0px 0px 0px"
					margin="0 0px 0px 0px"
					sm-height="fit-content"
					sm-min-height="fit-content"
					text-decoration-line="initial"
					sm-active-color="#e7369a"
				>
					See all
				</Link>
			</Box>
			<Section
				padding="0px 0px 0px 0px"
				justify-content="space-between"
				display="flex"
				width="100% border-box"
				min-width="100%"
				max-width="100%"
				quarkly-title="Articles"
				height="fit-content"
				margin="0 0 0 0"
				xl-width="100% border-box"
				xl-max-width="100%"
			>
				<Override
					slot="SectionContent"
					margin="0px 0 0px 0"
					flex-direction="row"
					justify-content="space-between"
					width="100% border-box"
					max-width="100%"
					height="fit-content"
					align-items="stretch"
					sm-flex-wrap="wrap"
					xl-width="100% border-box"
					xl-flex-wrap="wrap"
					xl-justify-content="space-between"
					min-width="100%"
					flex-wrap="wrap"
				/>
				<Box
					min-width="100px"
					min-height="fit-content"
					display="flex"
					padding="32px 32px 32px 32px"
					height="100% border-box"
					background="--color-aiscDavisBlack"
					border-radius="10px"
					quarkly-title="Article"
					margin="0px 24px 24px 0px"
					order="-1"
					flex="0 1 auto"
					align-self="auto"
					sm-margin="0px 0 64px 0px"
					flex-direction="column"
					sm-padding="24px 24px 24px 24px"
					align-items="stretch"
					xl-margin="0px 48px 24px 0px"
					width="auto"
					max-width="42%"
					sm-max-width="100%"
				>
					<Image
						src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20xavier%20article.png?v=2024-01-08T17:59:32.381Z"
						display="block"
						padding="0px 0px 0px 0px"
						object-fit="cover"
						width="100% border-box"
						min-width="100%"
						height="264px"
						min-height="fit-content100%"
						border-radius="5px"
						margin="0px 0px 24px 0px"
						object-position="50%40%"
						sm-height="200px"
						srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20xavier%20article.png?v=2024-01-08T17%3A59%3A32.381Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20xavier%20article.png?v=2024-01-08T17%3A59%3A32.381Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20xavier%20article.png?v=2024-01-08T17%3A59%3A32.381Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20xavier%20article.png?v=2024-01-08T17%3A59%3A32.381Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20xavier%20article.png?v=2024-01-08T17%3A59%3A32.381Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20xavier%20article.png?v=2024-01-08T17%3A59%3A32.381Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20xavier%20article.png?v=2024-01-08T17%3A59%3A32.381Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						width="100% border-box"
						quarkly-title="Text"
						padding="0px 0px 0px 0"
						display="flex"
						flex-direction="column"
						max-width="100%"
					>
						<Link
							href="https://medium.com/@humansforai/using-ai-to-translate-signed-languages-f6a473b4bc09"
							color="--aiscDavisBlue"
							font="500 20px sans-serif"
							text-align="left"
							target="_blank"
							padding="0px 0px 16px 0px"
							hover-color="--aiscDavisPink"
							active-color="#e7369a"
						>
							Using AI to Translate Signed Languages
						</Link>
						<Text
							margin="0px 0px 0px 0px"
							color="--light"
							text-align="left"
							font="200 18px sans-serif"
							width="fit-content"
							padding="0px 0px 16px 0px"
						>
							By Xavier Contreras
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							color="--aiscDavisLightGray"
							text-align="left"
							font="200 18px sans-serif"
							width="fit-content"
							text-overflow="ellipsis"
							height="fit-content"
						>
							“There is an inherent variability in the way people sign that cannot be properly captured just by tracking hand movements. Does this mean that translating sign language is impossible? Of course not, it just means that there is a lot more data that needs to be taken into...”
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="fit-content"
					display="flex"
					padding="32px 32px 32px 32px"
					height="fit-content"
					background="--color-aiscDavisBlack"
					border-radius="10px"
					quarkly-title="Article"
					margin="0px 0 0px 0px"
					order="-1"
					flex="0 1 auto"
					align-self="auto"
					sm-margin="0px 0 0 0px"
					flex-direction="column"
					sm-padding="24px 24px 24px 24px"
					align-items="stretch"
					width="auto"
					max-width="42%"
					sm-max-width="100%"
				>
					<Image
						src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20sean%20article.png?v=2024-01-08T17:59:32.397Z"
						display="block"
						padding="0px 0px 0px 0px"
						object-fit="cover"
						min-width="100%"
						height="264px"
						min-height="fit-content100%"
						border-radius="5px"
						width="100% border-box"
						margin="0px 0px 24px 0px"
						object-position="50%70%"
						sm-height="200px"
						srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20sean%20article.png?v=2024-01-08T17%3A59%3A32.397Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20sean%20article.png?v=2024-01-08T17%3A59%3A32.397Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20sean%20article.png?v=2024-01-08T17%3A59%3A32.397Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20sean%20article.png?v=2024-01-08T17%3A59%3A32.397Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20sean%20article.png?v=2024-01-08T17%3A59%3A32.397Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20sean%20article.png?v=2024-01-08T17%3A59%3A32.397Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20davis%20sean%20article.png?v=2024-01-08T17%3A59%3A32.397Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						width="100% border-box"
						quarkly-title="Text"
						padding="0px 0px 0px 0"
						display="flex"
						flex-direction="column"
					>
						<Link
							href="https://medium.com/@humansforai/balancing-progress-with-responsibility-insights-from-the-extropians-and-singularity-33e3530413b8"
							color="--aiscDavisBlue"
							font="500 20px sans-serif"
							text-align="left"
							target="_blank"
							padding="0px 0px 16px 0px"
							hover-color="--aiscDavisPink"
							active-color="#e7369a"
						>
							Balancing Progress with Responsibility: Insights from the Extropians and Singularity{" "}
						</Link>
						<Text
							margin="0px 0px 0px 0px"
							color="--light"
							text-align="left"
							font="200 18px sans-serif"
							width="fit-content"
							padding="0px 0px 16px 0px"
						>
							By Sean Vanderaa
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							color="--aiscDavisLightGray"
							text-align="left"
							font="200 18px sans-serif"
							width="fit-content"
							text-overflow="ellipsis"
							height="fit-content"
						>
							“The desire to expand human knowledge granted us the ability to become the most dominant and developed species on the planet. However, as much as the drive to increase our intelligence is the reason for every...”
						</Text>
					</Box>
				</Box>
			</Section>
		</Section>
		<Section
			color="#2e2e2e"
			display="flex"
			overflow-x="visible"
			overflow-y="visible"
			align-items="flex-start"
			max-height="100%"
			min-height="fit-content"
			height="100%"
			text-align="center"
			padding="100px 160px 124px 160px"
			quarkly-title="Upcoming Events"
			justify-content="center"
			max-width="100%"
			width="100% border-box"
			background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20website%20what%20we%20offer%20bg.png?v=2024-01-08T04:25:15.313Z) 0% 0% /cover repeat scroll padding-box"
			sm-padding="84px 36px 84px 36px"
		>
			<Override
				slot="SectionContent"
				align-items="flex-start"
				flex-direction="column"
				margin="0px 0 0px 0"
				max-width="none"
				width="100%"
				sm-height="auto"
				sm-min-height="fit-content"
				sm-margin="0 0 0px 0"
			/>
			<Text
				margin="0px 0px 0px 0px"
				background="rgba(0, 0, 0, 0)"
				color="--light"
				text-align="center"
				font="normal 500 36px --fontFamily-sans"
				padding="0px 0px 16px 0px"
				min-width="fit-content"
				sm-padding="0px 0px 0 0px"
				sm-margin="0px 0px 16px 0px"
			>
				Upcoming Events
			</Text>
			<Text
				margin="0px 0px 0px 0px"
				background="rgba(0, 0, 0, 0)"
				color="--aiscDavisLightGray"
				text-align="center"
				font="normal 300 24px --fontFamily-sans"
				padding="0px 0px 100px 0px"
				min-width="fit-content"
				sm-text-align="left"
				sm-padding="0px 0px 0 0px"
				sm-margin="0px 0px 64px 0px"
			>
				Stay tuned on{" "}
				<Link
					color="--aiscDavisLightGray"
					href="https://www.instagram.com/aiscdavis/?hl=en"
					target="_blank"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					hover-color="--aiscDavisPink"
					active-color="--aiscDavisPink"
				>
					Instagram
				</Link>
				{" "}for the latest details!{" "}
			</Text>
			<Section
				padding="0px 0px 0 0px"
				justify-content="space-between"
				width="100% border-box"
				display="flex"
				max-width="fit-content"
				min-width="100%"
				quarkly-title="Events"
				align-items="flex-start"
				margin="0 0 0 0"
				sm-height="fit-content"
				sm-min-height="fit-content"
				sm-margin="0 0 0 0"
			>
				<Override
					slot="SectionContent"
					margin="0px 0 0px 0"
					align-items="flex-start"
					flex-direction="row"
					justify-content="space-between"
					max-width="100%"
					min-width="100%"
					sm-flex-wrap="wrap"
					flex-wrap="wrap"
					width="100% border-box"
					sm-justify-content="space-between"
				/>
				<Box
					display="block"
					max-height="fit-content"
					padding="0 0 0 0"
					quarkly-title="Event"
					min-width="184px"
					margin="0 48px 48px 0"
					width="184px"
					max-width="184px"
					min-height="fit-content"
					height="fit-content"
					sm-width="132px"
					sm-min-width="132px"
					sm-max-width="132px"
					sm-margin="0 16px 48px 0"
				>
					<Text
						margin="0px 0px 24px 0px"
						color="--aiscDavisBlack"
						text-align="left"
						font="400 12px/150% sans-serif"
						width="fit-content"
						background="#51B7FF"
						padding="4px 12px 4px 12px"
						border-radius="4px"
					>
						Week 1
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--light"
						text-align="left"
						font="500 20px sans-serif"
						padding="0px 0px 12px 0px"
					>
						2ND ANNUAL TECH MIXER
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--aiscDavisLightGray"
						text-align="left"
						font="200 18px/130% sans-serif"
						width="fit-content"
						padding="0px 0px 12px 0px"
						min-width="fit-content"
						max-width="fit-content"
					>
						Wed, 10/2
						<br />
						6:30-9:30pm
						<br />
						California Hall
					</Text>
				</Box>
				<Box
					display="block"
					max-height="fit-content"
					padding="0 0 0 0"
					quarkly-title="Event"
					margin="0 48px 48px 0"
					width="184px"
					min-width="184px"
					max-width="184px"
					min-height="fit-content"
					height="fit-content"
					sm-width="132px"
					sm-min-width="132px"
					sm-max-width="132px"
					sm-margin="0 0 48px 0"
				>
					<Text
						margin="0px 0px 24px 0px"
						color="--aiscDavisBlack"
						text-align="left"
						font="400 12px/150% sans-serif"
						width="fit-content"
						background="#51B7FF"
						padding="4px 12px 4px 12px"
						border-radius="4px"
					>
						Week 2
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--light"
						text-align="left"
						font="500 20px sans-serif"
						padding="0px 0px 8px 0px"
					>
						GENERAL MEETING #1
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--aiscDavisLightGray"
						text-align="left"
						font="200 18px/130% sans-serif"
						width="fit-content"
						padding="0px 0px 12px 0px"
						min-width="fit-content"
						max-width="fit-content"
					>
						Thurs, 10/10
						<br />
						TBD
						<br />
						TBD
					</Text>
				</Box>
				<Box
					display="block"
					max-height="fit-content"
					padding="0 0 0 0"
					width="184px"
					quarkly-title="Event"
					margin="0 48px 48px 0"
					min-width="184px"
					max-width="184px"
					min-height="fit-content"
					height="fit-content"
					sm-width="132px"
					sm-min-width="132px"
					sm-max-width="132px"
					sm-margin="0 16px 48px 0"
				>
					<Text
						margin="0px 0px 24px 0px"
						color="--aiscDavisBlack"
						text-align="left"
						font="400 12px/150% sans-serif"
						width="fit-content"
						background="#51B7FF"
						padding="4px 12px 4px 12px"
						border-radius="4px"
					>
						Week 3
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--light"
						text-align="left"
						font="500 20px sans-serif"
						padding="0px 0px 8px 0px"
						overflow-x="visible"
					>
						WORKSHOP & GENERAL MEETING #2
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--aiscDavisLightGray"
						text-align="left"
						font="200 18px/130% sans-serif"
						width="fit-content"
						padding="0px 0px 12px 0px"
						min-width="fit-content"
						max-width="fit-content"
					>
						Thurs, 10/17
						<br />
						TBD
						<br />
						TBD
					</Text>
				</Box>
				<Box
					display="block"
					max-height="fit-content"
					padding="0 0 0 0"
					width="184px"
					quarkly-title="Event"
					xl-margin="0px 24px 0px 0px"
					margin="0 48px 48px 0"
					min-width="184px"
					max-width="184px"
					min-height="fit-content"
					height="fit-content"
					sm-width="132px"
					sm-min-width="132px"
					sm-max-width="132px"
					sm-margin="0 0 48px 0"
				>
					<Text
						margin="0px 0px 24px 0px"
						color="--aiscDavisBlack"
						text-align="left"
						font="400 12px/150% sans-serif"
						width="fit-content"
						background="#51B7FF"
						padding="4px 12px 4px 12px"
						border-radius="4px"
					>
						Week 4
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--light"
						text-align="left"
						font="500 20px sans-serif"
						padding="0px 0px 8px 0px"
					>
						WEEKEND{" "}
						<br />
						WORKSHOP #1
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--aiscDavisLightGray"
						text-align="left"
						font="200 18px/130% sans-serif"
						width="fit-content"
						padding="0px 0px 12px 0px"
						min-width="fit-content"
						max-width="fit-content"
					>
						Sat, 10/26
						<br />
						TBD
						<br />
						TBD
					</Text>
				</Box>
				<Box
					display="block"
					max-height="fit-content"
					padding="0 0 0 0"
					width="184px"
					quarkly-title="Event"
					margin="0 48px 48px 0"
					xl-margin="0px 0 0px 0px"
					min-width="184px"
					max-width="184px"
					min-height="fit-content"
					height="fit-content"
					sm-width="132px"
					sm-min-width="132px"
					sm-max-width="132px"
					sm-margin="0 16px 48px 0"
				>
					<Text
						margin="0px 0px 24px 0px"
						color="--aiscDavisBlack"
						text-align="left"
						font="400 12px/150% sans-serif"
						width="fit-content"
						background="#51B7FF"
						padding="4px 12px 4px 12px"
						border-radius="4px"
					>
						Week 5
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--light"
						text-align="left"
						font="500 20px sans-serif"
						padding="0px 0px 12px 0px"
					>
						YOLOBERRY SOCIAL
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--aiscDavisLightGray"
						text-align="left"
						font="200 18px/130% sans-serif"
						width="fit-content"
						padding="0px 0px 12px 0px"
						min-width="fit-content"
						max-width="fit-content"
					>
						Tues, 10/28
						<br />
						12:00-10:00pm
						<br />
						YoloBerry
					</Text>
				</Box>
				<Box
					display="block"
					max-height="fit-content"
					padding="0 0 0 0"
					width="184px"
					quarkly-title="Event"
					margin="0 48px 48px 0"
					min-width="184px"
					max-width="184px"
					min-height="fit-content"
					height="fit-content"
					sm-width="132px"
					sm-min-width="132px"
					sm-max-width="132px"
					sm-margin="0 0 48px 0"
				>
					<Text
						margin="0px 0px 24px 0px"
						color="--aiscDavisBlack"
						text-align="left"
						font="400 12px/150% sans-serif"
						width="fit-content"
						background="#51B7FF"
						padding="4px 12px 4px 12px"
						border-radius="4px"
					>
						Week 6
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--light"
						text-align="left"
						font="500 20px sans-serif"
						padding="0px 0px 8px 0px"
					>
						MED AI
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--aiscDavisLightGray"
						text-align="left"
						font="200 18px/130% sans-serif"
						width="fit-content"
						padding="0px 0px 12px 0px"
						min-width="fit-content"
						max-width="fit-content"
					>
						TBD
						<br />
						TBD
						<br />
						TBD
					</Text>
				</Box>
				<Box
					display="block"
					max-height="fit-content"
					padding="0 0 0 0"
					width="184px"
					quarkly-title="Event"
					margin="0 48px 48px 0"
					min-width="184px"
					max-width="184px"
					min-height="fit-content"
					height="fit-content"
					sm-width="132px"
					sm-min-width="132px"
					sm-max-width="132px"
					sm-margin="0 16px 48px 0"
				>
					<Text
						margin="0px 0px 24px 0px"
						color="--aiscDavisBlack"
						text-align="left"
						font="400 12px/150% sans-serif"
						width="fit-content"
						background="#51B7FF"
						padding="4px 12px 4px 12px"
						border-radius="4px"
					>
						Week 7
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--light"
						text-align="left"
						font="500 20px sans-serif"
						padding="0px 0px 8px 0px"
					>
						3RD ANNUAL AI SYMPOSIUM
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--aiscDavisLightGray"
						text-align="left"
						font="200 18px/130% sans-serif"
						width="fit-content"
						padding="0px 0px 12px 0px"
						min-width="fit-content"
						max-width="fit-content"
					>
						Thurs, 11/14
						<br />
						TBD
						<br />
						TBD
					</Text>
				</Box>
				<Box
					display="block"
					max-height="fit-content"
					padding="0 0 0 0"
					width="184px"
					quarkly-title="Event"
					margin="0 48px 48px 0"
					min-width="184px"
					max-width="184px"
					min-height="fit-content"
					height="fit-content"
					sm-width="132px"
					sm-min-width="132px"
					sm-max-width="132px"
					sm-margin="0 0 48px 0"
				>
					<Text
						margin="0px 0px 24px 0px"
						color="--aiscDavisBlack"
						text-align="left"
						font="400 12px/150% sans-serif"
						width="fit-content"
						background="#51B7FF"
						padding="4px 12px 4px 12px"
						border-radius="4px"
					>
						Week 8
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--light"
						text-align="left"
						font="500 20px sans-serif"
						padding="0px 0px 8px 0px"
					>
						AISC QUARTERLY SHOWCASE
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--aiscDavisLightGray"
						text-align="left"
						font="200 18px/130% sans-serif"
						width="fit-content"
						padding="0px 0px 12px 0px"
						min-width="fit-content"
						max-width="fit-content"
					>
						Thurs, 11/21
						<br />
						TBD
						<br />
						TBD
					</Text>
				</Box>
				<Box
					display="block"
					max-height="fit-content"
					padding="0 0 0 0"
					width="184px"
					quarkly-title="Event"
					margin="0 48px 48px 0"
					min-width="184px"
					max-width="184px"
					min-height="fit-content"
					height="fit-content"
					sm-width="132px"
					sm-min-width="132px"
					sm-max-width="132px"
					sm-margin="0 16px 48px 0"
				>
					<Text
						margin="0px 0px 24px 0px"
						color="--aiscDavisBlack"
						text-align="left"
						font="400 12px/150% sans-serif"
						width="fit-content"
						background="#51B7FF"
						padding="4px 12px 4px 12px"
						border-radius="4px"
					>
						Week 9
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--light"
						text-align="left"
						font="500 20px sans-serif"
						padding="0px 0px 8px 0px"
					>
						END OF QUARTER WRAP-UP
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--aiscDavisLightGray"
						text-align="left"
						font="200 18px/130% sans-serif"
						width="fit-content"
						padding="0px 0px 12px 0px"
						min-width="fit-content"
						max-width="fit-content"
					>
						Thurs, 11/28
						<br />
						TBD
						<br />
						TBD
					</Text>
				</Box>
				<Box
					display="block"
					max-height="fit-content"
					padding="0 0 0 0"
					width="184px"
					quarkly-title="Event"
					margin="0 48px 48px 0"
					min-width="184px"
					max-width="184px"
					min-height="fit-content"
					height="fit-content"
					sm-width="132px"
					sm-min-width="132px"
					sm-max-width="132px"
					sm-margin="0 16px 48px 0"
				/>
				<Box
					display="block"
					max-height="fit-content"
					padding="0 0 0 0"
					width="184px"
					quarkly-title="Event"
					min-width="184px"
					max-width="184px"
					margin="0 48px 48px 0"
					min-height="fit-content"
					height="fit-content"
					sm-width="132px"
					sm-min-width="132px"
					sm-max-width="132px"
					sm-margin="0 0 48px 0"
				/>
			</Section>
		</Section>
		<Section
			display="flex"
			padding="100px 160px 124px 160px"
			width="100% border-box"
			height="100%"
			background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20website%20what%20we%20offer%20bg.png?v=2024-01-08T04:25:15.313Z) 0% 0% /cover repeat scroll padding-box"
			border-color="rgb(46, 46, 46)"
			max-height="100%"
			justify-content="center"
			align-items="flex-start"
			text-align="center"
			color="#2e2e2e"
			inner-min-width="default"
		>
			<Override
				slot="SectionContent"
				background="light"
				width="100% "
				padding="0px 0px 16px 0px"
				align-self="auto"
				position="static"
				height="500px"
			/>
			<Text
				margin="0px 0px 0px 0px"
				font="normal 500 36px --fontFamily-sans"
				color="--light"
				width="fit-content"
				padding="0px 0px 20px 0px"
			>
				Check out our open roles!
			</Text>
			<Text
				margin="0px 0px 0px 0px"
				color="--aiscDavisLightGray"
				min-width="8px"
				width="fit-content"
				padding="0px 0px 80px 0px"
				font="normal 300 24px --fontFamily-sans"
				display="block"
				align-items="flex-start"
				height="28px"
				text-align="left"
			>
				Roles are now open for the 2024-2025 quarter! Be sure to apply, spots are limited, and applications close{" "}
				<Span
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<Em
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						October 4 at 11:59 pm
					</Em>
				</Span>
				.
			</Text>
			<Link
				href="https://forms.gle/v1kSkLn5ToarN3cFA"
				color="--light"
				link-target="_blank"
				font="500 20px sans-serif"
				width="fit-content"
				padding="0px 0px 30px 0px"
				target="_blank"
			>
				USER EXPERIENCE RESEARCH APPLICATION
			</Link>
			<Link
				href="#https://forms.gle/Zwk1n19aLPTwx4rP8"
				color="--light"
				font="500 20px sans-serif"
				width="fit-content"
				padding="0px 0px 30px 0px"
				target="_blank"
			>
				EVENT PHOTOGRAPHER APPLICATION
			</Link>
			<Link
				href="#https://forms.gle/NwNk131xqknkjNFm6"
				color="--light"
				font="20px sans-serif"
				target="_blank"
				width="fit-content"
				padding="0px 0px 30px 0px"
			>
				SOFTWARE ENGINEERING APPLICATION
			</Link>
			<Link
				href="#https://forms.gle/w3rtm8rkeZ9wkiP7A"
				color="#ffffff"
				target="_blank"
				width="fit-content"
				font="20px sans-serif"
				padding="0px 0px 30px 0px"
			>
				BEGINNER PROJECTS PROJECT MANAGER APPLICATION
			</Link>
			<Link
				href="#https://forms.gle/Kzvv7LS8crQpBESj8"
				color="#ffffff"
				padding="0 0px 30px 0px"
				target="_blank"
				font="20px sans-serif"
				width="fit-content"
			>
				EDUCATION TEAM APPLICATION
			</Link>
			<Link
				href="#https://forms.gle/mSkUVxpeN2b6kXkE9"
				color="#ffffff"
				target="_blank"
				width="fit-content"
				font="20px sans-serif"
				padding="0px 0px 30px 0px"
			>
				R&D RESEARCH PROJECT DIVISION APPLICATION
			</Link>
			<Link
				href="https://forms.gle/DnUJc9zYaE1wWcVV7#"
				color="#ffffff"
				target="_blank"
				width="fit-content"
				font="20px sans-serif"
				padding="0px 0px 30px 0px"
			>
				R&D TECHNICAL WRITING DIVISION APPLICATION
			</Link>
		</Section>
		<Box
			min-width="100px"
			min-height="100px"
			background="#000000 url(https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/aisc%20website%20what%20we%20offer%20bg.png?v=2024-01-08T04:25:15.313Z) 0% 0%/cover"
			max-width="100%"
			max-height="100%"
			display="flex"
		>
			<Section
				padding="64px 160px 72px 160px"
				sm-padding="48px 36px 24px 36px"
				color="--aiscDavisBlack"
				quarkly-title="Footer"
				background="--color-aiscDavisBlack"
				width="100% border-box"
				sm-width="100% border-box"
			>
				<Override
					slot="SectionContent"
					flex-direction="column"
					justify-content="space-between"
					width="100%"
					sm-flex-wrap="wrap"
				/>
				<Box
					min-width="100px"
					min-height="fit-content"
					display="flex"
					justify-content="space-between"
					sm-flex-direction="column"
					align-items="flex-start"
					height="auto"
					width="100% border-box"
					margin="0px 0px 148px 0px"
					sm-margin="0px 0px 64px 0px"
				>
					<Box
						min-width="100px"
						min-height="fit-content"
						quarkly-title="Newsletter"
						display="flex"
						flex-direction="column"
						justify-content="space-between"
						width="100% border-box"
						margin="0px 48px 0px 0"
						height="auto"
						padding="0px 0px 0px 0px"
						align-items="stretch"
						sm-margin="0px 0px 64px 0px"
						sm-min-width="100%"
					>
						<Box
							min-width="100px"
							min-height="fit-content"
							quarkly-title="Text"
							height="fit-content"
							margin="0px 0px 64px 0px"
							sm-width="100% border-box"
							sm-min-width="100%"
							sm-margin="0px 0px 24px 0px"
						>
							<Text margin="0px 0px 16px 0px" font="500 20px sans-serif" color="--aiscDavisBlue" padding="0px 0px 0 0px">
								JOIN OUR NEWSLETTER
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								font="200 18px sans-serif"
								color="--aiscDavisLightGray"
								padding="0px 0px 0 0px"
								sm-width="100% border-box"
							>
								Get weekly club updates, AI news, and resources for learning more about AI.
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="fit-content"
							quarkly-title="Form"
							height="fit-content"
							display="flex"
							sm-width="100% border-box"
							sm-flex-wrap="wrap"
							sm-justify-content="space-between"
						>
							<Input
								display="block"
								placeholder-color="--aiscDavisLightGray"
								border-radius="4px"
								border-width="1px"
								placeholder-font="normal 200 14px/2 sans-serif"
								type="email"
								placeholder="Enter your email..."
								required
								color="--light"
								margin="0px 16px 0px 0px"
								width="300px"
								font="normal 300 14px/1.5 sans-serif"
								hover-font="normal 300 14px/1.5 sans-serif"
								border-color="--color-aiscDavisLightGray"
								hover-border-color="--color-light"
								sm-margin="0px 0px 16px 0px"
								sm-width="100% border-box"
								sm-height="42px"
								sm-min-width="100%"
								background="--color-aiscDavisBlack"
							/>
							<Button
								border-radius="4px"
								font="normal 300 16px/1.5 sans-serif"
								color="--light"
								border-width="1px"
								border-color="--color-aiscDavisPink"
								border-style="solid"
								background="--color-aiscDavisPink"
								hover-background="#e7369a"
								hover-color="--light"
								transition="all 0.1s ease 0s"
								sm-height="42px"
								sm-width="100% border-box"
								sm-min-width="100%"
								focus-background="#e7369a"
								focus-color="--aiscDavisBlack"
								active-color="--dark"
								active-background="#e7369a"
								active-transition="all 0.1s ease 0s"
								sm-active-transition="all 0.1s --transitionTimingFunction-sharp 0s"
								hover-transition="all 0.1s ease 0s"
								sm-active-background="#e7369a"
								sm-hover-background="#e7369a"
								sm-focus-background="#e7369a"
							>
								Subscribe
							</Button>
						</Box>
					</Box>
					<Box
						min-width="100px"
						min-height="fit-content"
						quarkly-title="Socials"
						display="flex"
						flex-direction="column"
						height="fit-content"
						width="fit-content"
						lg-width="auto"
						xl-width="auto"
						margin="0px 24px 0px 0px"
					>
						<Text margin="0px 0px 16px 0px" color="--aiscDavisBlue" font="500 20px sans-serif">
							CONTACT
						</Text>
						<Link
							href="https://www.instagram.com/aiscdavis?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
							color="--aiscDavisLightGray"
							font="200 18px sans-serif"
							padding="0px 0px 8px 0px"
							hover-color="#FF43AD"
							transition="all 0.3s ease 0s"
							target="_blank"
							border-color="--color-aiscDavisLightGray"
							text-decoration-line="initial"
						>
							Instagram
						</Link>
						<Link
							href="https://www.linkedin.com/company/ai-student-collective"
							color="--aiscDavisLightGray"
							font="200 18px sans-serif"
							padding="0px 0px 8px 0px"
							hover-color="#FF43AD"
							transition="all 0.3s ease 0s"
							target="_blank"
							text-decoration-line="initial"
						>
							LinkedIn
						</Link>
						<Link
							href="https://discord.gg/nVeEWu5ees"
							color="--aiscDavisLightGray"
							font="200 18px sans-serif"
							padding="0px 0px 8px 0px"
							hover-color="#FF43AD"
							transition="all 0.3s ease 0s"
							target="_blank"
							text-decoration-line="initial"
						>
							Discord
						</Link>
						<Link
							href="https://youtube.com/@aiscdavis?feature=shared"
							color="--aiscDavisLightGray"
							font="200 18px sans-serif"
							padding="0px 0px 8px 0px"
							hover-color="#FF43AD"
							transition="all 0.3s ease 0s"
							target="_blank"
							text-decoration-line="initial"
						>
							YouTube
						</Link>
						<Link
							href="mailto:aiscdavis@humansforai.com"
							color="--aiscDavisLightGray"
							font="200 18px sans-serif"
							padding="0px 0px px 0px"
							hover-color="#FF43AD"
							transition="all 0.3s ease 0s"
							target="_self"
							text-decoration-line="initial"
						>
							Email
						</Link>
					</Box>
				</Box>
				<Box
					min-width="100px"
					quarkly-title="Logo"
					display="flex"
					flex-direction="row"
					height="fit-content"
					margin="0px 0px 0 0px"
					align-items="center"
					flex-wrap="wrap"
					xl-width="fit-content"
					xl-flex-direction="row"
					lg-width="fit-content"
					sm-height="fit-content"
					sm-margin="0px 0px 0 0px"
					justify-content="center"
					min-height="fit-content"
					sm-width="100% border-box"
				>
					<Image
						src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Logo%20Outline%20White.png?v=2023-11-07T22:12:47.498Z"
						display="block"
						width="32px"
						padding="0px 0px px 0px"
						height="32px"
						margin="0px 16px 24px 0px"
						min-height="32px"
						min-width="32px"
						srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Logo%20Outline%20White.png?v=2023-11-07T22%3A12%3A47.498Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Logo%20Outline%20White.png?v=2023-11-07T22%3A12%3A47.498Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Logo%20Outline%20White.png?v=2023-11-07T22%3A12%3A47.498Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Logo%20Outline%20White.png?v=2023-11-07T22%3A12%3A47.498Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Logo%20Outline%20White.png?v=2023-11-07T22%3A12%3A47.498Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Logo%20Outline%20White.png?v=2023-11-07T22%3A12%3A47.498Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AISC%20Logo%20Outline%20White.png?v=2023-11-07T22%3A12%3A47.498Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box
						min-width="fit-content"
						quarkly-title="Text"
						display="flex"
						flex-direction="row"
						height="fit-content"
						margin="0px 0px 24px 0px"
						lg-width="fit-content"
						xl-width="fit-content"
					>
						<Text
							margin="0px 0px 0px 0px"
							color="--light"
							font="500 24px sans-serif"
							min-width="fit-content"
							lg-width="fit-content"
							xl-width="fit-content"
						>
							AISC{" "}
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							color="--light"
							font="100 24px sans-serif"
							min-width="fit-content"
							lg-width="fit-content"
							xl-width="fit-content"
						>
							@ UC DAVIS
						</Text>
					</Box>
				</Box>
			</Section>
		</Box>
	</Theme>;
});